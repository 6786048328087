'use client';

import { Button, ButtonProps } from '@/components/ui/button';
import { cn } from '@/lib/utils';
import { LoaderCircle } from 'lucide-react';
import { type FieldValues, type UseFormReturn } from 'react-hook-form';
export default function SubmitButton<Values extends FieldValues = FieldValues>({
  form,
  submitting,
  disabled,
  allowSubmitDefault,
  label = 'Submit',
  submittingLabel = 'Submitting...',
  ...props
}: {
  /**
   * If form is provided, and
   *  - submitting is not provided, it will be set to form.formState.isSubmitting
   *  - disabled is not provided, the button will be disabled if submitting or the
   * form is not dirty (when only default values are present)
   */
  form?: UseFormReturn<Values>;
  submitting?: boolean;
  label?: string;
  submittingLabel?: string;
  /**
   * The default behavior disables the button unless the form isDirty.
   * This will override it and allow submitting with the default values.
   */
  allowSubmitDefault?: boolean;
} & Omit<ButtonProps, 'form'>) {
  if (submitting === undefined && form) {
    submitting = form.formState.isSubmitting;
  }
  if (disabled === undefined && form) {
    disabled = submitting || !allowSubmitDefault && !form.formState.isDirty;
  }

  // Force rerender on all changes
  const watchedFields = form?.watch();
  return <Button variant={'default'} disabled={disabled} {...props} className={cn('mt-4', props.className)} data-sentry-element="Button" data-sentry-component="SubmitButton" data-sentry-source-file="SubmitButton.tsx">
      {submitting ? <>
          <LoaderCircle size={16} className="mr-2 animate-spin" />
          {submittingLabel}
        </> : label}
    </Button>;
}