import { createBrowserSupabase } from '@/lib/supabase/browser';
export async function fetchChats(userId?: string) {
  const res = await createBrowserSupabase().from('chats').select(`*, 
      messages(
        *,
        user:users(*)
      ), 
      chat_participants!inner(
        *, 
        user:users(*)
      )
      `).eq('chat_participants.user_id', userId || '').not('messages.is_removed', 'is', true).order('created_at', {
    ascending: false
  });
  return res;
}
export type SupabaseChats = NonNullable<Awaited<ReturnType<typeof fetchChats>>['data']>;
export type SupabaseChat = SupabaseChats[number];