'use client';

import { fetchChats, SupabaseChats } from '@/app/(chat)/Sidebar/fetch-chats-browser';
import { captureException } from '@sentry/nextjs';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { toast } from 'sonner';
import { useImmer } from 'use-immer';
import { getBrowserUser } from '../supabase/browser';
const LOCAL_STORAGE_KEY = 'sidebar';
interface SidebarContext {
  isSidebarOpen: boolean;
  toggleSidebar: () => void;
  isLoading: boolean;
  isMobile: boolean;
  chats: SupabaseChats;
  setInitialChats: (chats: SupabaseChats) => void;
  refreshChats: () => void;
}
const SidebarContext = React.createContext<SidebarContext | undefined>(undefined);
export function useSidebar() {
  const context = React.useContext(SidebarContext);
  if (!context) {
    throw new Error('useSidebarContext must be used within a SidebarProvider');
  }
  return context;
}
interface SidebarProviderProps {
  children: React.ReactNode;
}
export function SidebarProvider({
  children
}: SidebarProviderProps) {
  const [isSidebarOpen, setSidebarOpen] = useState(false);
  const [isLoading, setLoading] = useState(true);
  const [isMobile, setMobile] = useState(true);
  const [chats, setChats] = useImmer<SupabaseChats>([]);
  useEffect(() => {
    const value = localStorage.getItem(LOCAL_STORAGE_KEY);
    if (value === 'true' || !value && window.innerWidth >= 768) {
      setSidebarOpen(true);
    }
    function handleResize() {
      setMobile(window.innerWidth < 768);
    }
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  useEffect(() => {
    if (isMobile && isSidebarOpen) {
      setSidebarOpen(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMobile]);
  const toggleSidebar = () => {
    setSidebarOpen(value => {
      const newState = !value;
      localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(newState));
      return newState;
    });
  };
  const setInitialChats = React.useCallback((chats: SupabaseChats) => {
    setChats(chats);
    setLoading(false);
  }, [setChats]);
  const refreshChats = React.useCallback(async () => {
    await fetchChats((await getBrowserUser())?.id).then(res => {
      if (res.error) {
        console.error(res.error);
        captureException(res.error);
        toast.error('There was an error fetching your chats');
        return;
      }
      setChats(res.data);
    });
  }, [setChats]);
  return <SidebarContext.Provider value={{
    isSidebarOpen,
    toggleSidebar,
    isLoading,
    isMobile,
    chats,
    setInitialChats,
    refreshChats
  }} data-sentry-element="unknown" data-sentry-component="SidebarProvider" data-sentry-source-file="use-sidebar.tsx">
      {children}
    </SidebarContext.Provider>;
}